// src/components/HowItWorks.js
import React from 'react';
import './HowItWorks.css';
import VolleyballImage from '../images/Volleyball.jpg';
import SearchGIF from '../images/search.gif';
import SignUpGIF from '../images/signup.gif';

const HowItWorks = () => {
    return (
        <section id="how-it-works" className="how-it-works">
            <h2>How it works</h2>
            <div className="step">
                <div className="image-box">
                    <img src={SearchGIF} alt="Step 1" />
                </div>
                <div className="text-box">
                    <h3>Find your Athletic Club</h3>
                    <p>Use our search tool to find a club that has a shared commonality with you or to find a club associated with a Licensed Dietitian or Certified Personal Trainer</p>
                </div>
            </div>
            <div className="arrow">↓</div>
            <div className="step">
                <div className="image-box">
                    <img src={SignUpGIF} alt="Step 2" />
                </div>
                <div className="text-box">
                    <h3>Create an account</h3>
                    <p>After you find your Athletic Club, create an account to interact with other members in the club, including your Dietitian and Personal Trainer
                    </p>
                </div>
            </div>
            <div className="arrow">↓</div>
            <div className="step">
                <div className="image-box">
                    <img src={VolleyballImage} alt="Step 3" />
                </div>
                <div className="text-box">
                    <h3>Join your community</h3>
                    <p>Our platform offers many ways to join your community; schedule activities, matches, and pickup games, along with social interaction with other Athletic Club members</p>
                    <p>Our Dietitian and Personal Trainer affiliated Athletic Clubs are great to find your next provider that can offer nutritional & fitness support</p>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
