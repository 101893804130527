import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AthleticClub.css';

const AthleticClub = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div id="athletic-club" className="athletic-club">
            <h2>Athletic Club</h2>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>What is an Athletic Club?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        An Athletic Club is like a "Run Club". The same principal is there, a group of people schedule sports/activities to do together.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography>Who can start an Athletic Club?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Anyone! If you're name is "John Doe" and you wanted to start "John Doe Athletic Club", you absoloutely can.
                    </Typography>
                    <br></br>
                    <Typography>
                        We also allow businesses, communities, and dietitians & personal trainers to join with special features.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography>Businesses and Athletic Clubs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Become more accessible to your customers and prospective customers. If you provide an activity or sport, or are associated with nutrition, fitness, or athletics, join to grow your brand on Brocli.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography>Local Governments and Athletic Clubs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Make your community spaces more accessible to people in your community and adjacent communities. Our tool offers a way for people to see what athletic & activity spaces are available and reserve them. 
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography>Athletic Clubs for Dietitians & Personal Trainers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        For Dietitians and Personal Trainers, our platform lowers your marketing cost, helps with client acquisition, and provides an all-in-one platform.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default AthleticClub;
