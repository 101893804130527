// src/components/SearchBar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import './SearchBar.css';

const SearchBar = () => {
    const navigate = useNavigate();

    const handleSearch = () => {
        navigate("/invitation-only");
    };

    return (
        <section className="search-bar">
            <select>
                <option value="" disabled selected>Location</option>
                <option value="Dallas">Dallas</option>
                <option value="New York City">New York City</option>
                <option value="Austin">Austin</option>
                <option value="Miami">Miami</option>
                <option value="Chicago">Chicago</option>
                <option value="Atlanta">Atlanta</option>
                <option value="LA">LA</option>
                <option value="Bay Area">Bay Area</option>
                <option value="Seattle">Seattle</option>
            </select>
            <select>
                <option value="" disabled selected>Affiliation</option>
                <option value="Young Desis Athletic Club">Young Desis Athletic Club</option>
                <option value="Princeton Alumni Athletic Club">Princeton Alumni Athletic Club</option>
                <option value="Dallas Kayaking Club">Dallas Kayaking Club</option>
                <option value="Dallas Biking Club">Dallas Biking Club</option>
                <option value="USC Alumni Athletic Club">USC Alumni Athletic Club</option>
                <option value="Dallas Restaurant Workers Athletic Club">Dallas Restaurant Workers Athletic Club </option>
                <option value="Duke Alumni Athletic Club">Duke Alumni Athletic Club</option>
                <option value="Garland Athletic Club">Garland City Volleyball Club</option>
                <option value="Dietitian Club">Dietitian Club</option>
                <option value="Personal Training Club">Personal Training Club</option>
                <option value="UM Alumni Athletic Club">UM Alumni Athletic Club</option>
            </select>
            <select>
                <option value="" disabled selected>Activity</option>
                <option value="Basketball">Basketball</option>
                <option value="Soccer">Soccer</option>
                <option value="Tennis">Tennis</option>
                <option value="Lifting">Lifting</option>
                <option value="Run Club">Run Club</option>
                <option value="Volleyball">Volleyball</option>
                <option value="Pickleball">Pickleball</option>
                <option value="Golf">Golf</option>
                <option value="Yoga">Yoga</option>
                <option value="Pilates">Pilates</option>
                <option value="Dancing">Dancing</option>
            </select>
            <button onClick={handleSearch}>Search</button>
        </section>
    );
};

export default SearchBar;
