import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './AuthPage.css';

const AuthPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    isDietitian: "",
    isSportsProvider: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://script.google.com/macros/s/AKfycbzBs2PFxFqztNjMcjfOHTV4iTYNlsrW2bSb7mS26og9acsn93-e5F-_rhlHKAKBxgM/exec", formData);
      console.log(response.data);
      navigate('/invitation-only'); // Redirect to InvitationOnly page
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="auth-form">
          <h2>Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="firstName" placeholder="First Name" required onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Last Name" required onChange={handleChange} />
            <input type="email" name="email" placeholder="Email" required onChange={handleChange} />
            <label>Are you a Registered Dietitian or Certified Personal Trainer?</label>
            <div className="radio-group">
              <label>
                <input type="radio" name="isDietitian" value="yes" required onChange={handleChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="isDietitian" value="no" required onChange={handleChange} />
                No
              </label>
            </div>
            <label>Are you an activity or sports provider (kayaking rental company, tennis court owner, gym owner)?</label>
            <div className="radio-group">
              <label>
                <input type="radio" name="isSportsProvider" value="yes" required onChange={handleChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="isSportsProvider" value="no" required onChange={handleChange} />
                No
              </label>
            </div>
            <button type="submit">Continue</button>
          </form>
        </div>
        <div className="auth-form">
          <h2>Sign In to Your Account</h2>
          <form onSubmit={(e) => { e.preventDefault(); navigate('/invitation-only'); }}>
            <input type="text" placeholder="Username" required />
            <input type="password" placeholder="Password" required />
            <button type="submit">Sign In</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
