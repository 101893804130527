// src/components/HeroSection.js
import React from "react";
import './HeroSection.css';

const HeroSection = () => {
    return (
        <section className="hero">
            <h1>Find your<br></br> Athletic Club.</h1>
            <div className="hero-subtext">
                <p>Find your sport, your community, and your wellness.</p>
            </div>
        </section>
    );
};

export default HeroSection;
