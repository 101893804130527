// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import SearchBar from "./components/SearchBar";
import AuthPage from "./components/AuthPage";
import HowItWorks from "./components/HowItWorks";
import AthleticClub from "./components/AthleticClub";
import Dietitians from "./components/Dietitians";
import PersonalTrainers from "./components/PersonalTrainers";
import Footer from "./components/Footer"; // Import Footer
import InvitationOnly from "./components/InvitationOnly"; // Import InvitationOnly
import './App.css';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<><HeroSection/><SearchBar/><HowItWorks/><AthleticClub/><Dietitians/><PersonalTrainers/></>} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/invitation-only" element={<InvitationOnly />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
