// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleNavigation = (e, target) => {
        e.preventDefault();
        window.location.href = `/${target}`;
        closeMenu();
    };

    return (
        <div className="header-container">
            <header className="header">
                <Link to="/" className="logo">Brocli</Link>
                <nav className={`nav ${isOpen ? 'open' : ''}`}>
                    <a href="/" onClick={(e) => handleNavigation(e, '#how-it-works')}>How it works</a>
                    <a href="/" onClick={(e) => handleNavigation(e, '#athletic-club')}>Athletic Clubs</a>
                    <a href="/" onClick={(e) => handleNavigation(e, '#dietitians')}>Dietitians</a>
                    <a href="/" onClick={(e) => handleNavigation(e, '#personal-trainers')}>Personal Trainers</a>
                    <Link to="/auth" className="sign-in-button" onClick={closeMenu}>Sign In</Link>
                    <Link to="/auth" className="join-button" onClick={closeMenu}>Sign Up</Link>
                </nav>
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </button>
            </header>
        </div>
    );
};

export default Header;
