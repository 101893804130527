// src/components/InvitationOnly.js
import React from 'react';
import './InvitationOnly.css';

const InvitationOnly = () => {
    return (
        <div className="invitation-only">
            <h1>We are currently in invitation only mode</h1>
        </div>
    );
};

export default InvitationOnly;
