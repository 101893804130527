// src/components/PersonalTrainers.js
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './PersonalTrainers.css';

const PersonalTrainers = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div id="personal-trainers" className="personal-trainers">
            <h2>Personal Trainers</h2>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>How it works</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                        A personal trainer joins our platform, and lists their certificates, specialties, and availability for people to find them.
                    </Typography>
                    <br></br>
                    <Typography>
                        Our platform offers a forum tool to start your own Athletic Club, which allows existing clients and prospective clients to interact with you, along with scheduling sessions with you.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography>Advantages for Personal Trainers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                        <b>Marketing Costs:</b> We will advertise the platform to where people will come to you.
                    </Typography>
                    <br></br>
                    <Typography>
                        <b>Client Acquisition:</b> Our platform will be filled with individuals that are already nutrition, fitness, and wellness focused.
                    </Typography>
                    <br></br>
                    <Typography>
                        <b>All-in-one Platform:</b> Brocli will be an all-in-one wellness platform for end users and providers, we will eliminate the need to have multiple apps for Personal Trainers to interact with their clients.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography>Cost</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Right now, we are offering 18-months free, after the 18 months there will be a monthly subscription of $9.99/month.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography>Requirements for Personal Trainers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Have an active certification in personal training from an accredited fitness board.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography>Roadmap</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    For our providers (dietitians and personal trainers), we will offer immense support and tools to help you grow your practice and brand.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PersonalTrainers;
